<template>
  <router-view :key="$route.path" />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import config from './config'

export default {
  name: 'Transfer',
  setup() {
    const { MODULE_NAME, model, store } = config()
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, model)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })

    return {
      MODULE_NAME,
    }
  },
}
</script>
